<template>
    <v-app>
      <div class="default-head"
           :style="`background-image: url(${require('../../../assets/images/12booky-default.jpg')});`">
          <div class="top-logo">
            <b-img :src="require('../../../assets/images/12Booky-logo.png')" alt="profile-pic"/>
          </div>
          <div class="top-menu">
            <v-menu
              :close-on-content-click="closeOnContentClick"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-menu offset-y style="z-index: 999999;">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <img :src="selectedLang.image" alt="img-flaf" class="img-fluid mr-1" style="height: 16px; width: 16px;" /> {{ selectedLang.title }} <i class="ri-arrow-down-s-line"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(lang, i) in langsOptions" :key="`Lang${i}`" @click="langChange(lang)">
                      <v-list-item-title><img :src="lang.image" alt="img-flaf" class="img-fluid mr-2" /> {{ lang.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('sidebar.login') }}
                  <i class="ri-user-fill"></i>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <router-link :to="{ name: item.to }">
                    <v-list-item-title><i :class="item.icon"></i> {{ item.title }}</v-list-item-title>
                  </router-link>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div class="search-head" :class="fab ? 'fixed_search' : ''" ref="theSearchBar" >
            <div class="search-head-command">
              <i class="fas fa-search" style="margin-left: 10px;"></i>
              <span class="category_selected" v-if="selectedCategory">{{ selectedCategory }} <i class="far fa-times-circle" @click="removeSelectedCategory"></i></span>
              <b-form-input id="search" :class="(selectedCategory ? 'category_selected_text' : '')" @focus="showCategories" @input="showSearch" v-model="theSearch" :placeholder="$t('2023.category_or_name')" autocomplete="off"></b-form-input>
              <i class="far fa-times-circle remove_results" v-show="theSearch.length > 0" @click="deleteInput"></i>
              <i class="fas fa-map-marker-alt"></i>
              <b-form-input id="search1" @input="getAutocompleteLocation" v-model="theSearchLocation" :placeholder="$t('2023.location')" autocomplete="off"></b-form-input>
              <i class="far fa-times-circle remove_results" v-show="theSearchLocation.length > 0" @click="deleteLocation"></i>
              <v-btn color="primary" class="search-button"> {{ $t('reports.search')}} </v-btn>
            </div>
            <div class="search-location" v-show="theSearchLocationResults">
             <ul class="m-0 p-0">
               <li class="p-2 set-location" v-for="(item, key) in theSearchLocationResults" :key="key"  @click="setLocation(item.label)">{{ item.label }}</li>
             </ul>
            </div>
            <div class="search-result-category" v-show="displayCategories">
              <b-row>
                <b-col cols="3" @click="getCompaniesByCategory('recreation')"> <iq-card class="the-iq-card" ><div > <i class="fas fa-swimmer"></i>  <div>{{ $t('helper.recreation') }} </div></div></iq-card> </b-col>
                <b-col cols="3" @click="getCompaniesByCategory('medical')"> <iq-card class="the-iq-card"><div > <i class="fas fa-clinic-medical"></i>  <div> {{ $t('helper.medical') }}  </div></div></iq-card> </b-col>
                <b-col cols="3" @click="getCompaniesByCategory('dentist')"> <iq-card class="the-iq-card"><div > <i class="fas fa-tooth"></i>  <div> {{ $t('helper.dentist') }}  </div></div></iq-card> </b-col>
                <b-col cols="3" @click="getCompaniesByCategory('k9')"> <iq-card class="the-iq-card"><div > <i class="fas fa-dog"></i>  <div> {{ $t('helper.k9') }}  </div></div></iq-card> </b-col>
                <b-col cols="3" @click="getCompaniesByCategory('fitness')"> <iq-card class="the-iq-card"><div > <i class="fas fa-running"></i>  <div> {{ $t('helper.fitness') }}  </div></div></iq-card> </b-col>
                <b-col cols="3" @click="getCompaniesByCategory('barbershop')"> <iq-card class="the-iq-card"><div > <i class="fas fa-cut"></i>  <div> {{ $t('helper.barbershop') }}  </div></div></iq-card> </b-col>
                <b-col cols="3" @click="getCompaniesByCategory('beauty')"> <iq-card class="the-iq-card"><div > <i class="fas fa-person-booth"></i>  <div> {{ $t('helper.beauty') }}  </div></div></iq-card> </b-col>
                <b-col cols="3" @click="getCompaniesByCategory('spa')"> <iq-card class="the-iq-card"><div > <i class="fas fa-spa"></i>  <div> {{ $t('helper.spa') }}  </div></div></iq-card> </b-col>
                <b-col cols="3" @click="getCompaniesByCategory('auto')"> <iq-card class="the-iq-card"><div > <i class="fas fa-car"></i>  <div> {{ $t('helper.auto') }}  </div></div></iq-card> </b-col>
                <b-col cols="3" @click="getCompaniesByCategory('tattoo')"> <iq-card class="the-iq-card"><div > <i class="fas fa-pen-alt"></i>  <div> {{ $t('helper.tattoo') }}  </div></div></iq-card> </b-col>
                <b-col cols="3" @click="getCompaniesByCategory('installations')"> <iq-card class="the-iq-card"><div > <i class="fas fa-wrench"></i>  <div> {{ $t('helper.installations') }}  </div></div></iq-card> </b-col>
                <b-col cols="3" @click="getCompaniesByCategory('site')"> <iq-card class="the-iq-card"><div > <i class="fas fa-pencil-ruler"></i>  <div> {{ $t('helper.site') }}  </div></div></iq-card> </b-col>
              </b-row>
            </div>
            <div class="search-result-companies" v-show="input === 1 && resultCompanies.length > 0">
              <div class="row">
                <div class="col-lg-3 col-md-4 col-sm-6" v-for="(item, key) in resultCompanies" :key="key">

                    <v-card
                    :loading="loading"
                    class="mx-auto mt-3"
                    max-width="374"
                  >
                    <template slot="progress">
                      <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                      ></v-progress-linear>
                    </template>
                      <router-link :to="'/companies/' + item.token">
                        <v-img
                          height="150"
                          class="company_background"
                          :src="item.background || require('../../../assets/images/12cover.jpg')"
                        ></v-img>

                        <v-card-title class="company_name">
                          <img :src="item.logo || require('../../../assets/images/favicon.png')" class="company_logo"/>
                          <div>{{ item.name }}</div>
                        </v-card-title>

                        <v-card-text>

                          <div class="my-1 the-address">
                            {{ item.city }}, {{ item.address }}
                          </div>
                        </v-card-text>

                        <v-card-title class="todays_avalability" v-show="item.next_availability">
                          {{ $t('2023.next_availability') }}:
                          <v-chip-group
                            active-class="deep-purple accent-4 white--text availability-chips"
                            column
                          >
                            <v-chip>{{  item.next_availability }}</v-chip>
                          </v-chip-group>
                        </v-card-title>

                        <div class="text-center pb-2">
                          <router-link :to="'/companies/' + item.token + '#profile-book'">
                            <v-btn color="primary">{{ $t('2023.book_now') }}</v-btn>
                          </router-link>
                        </div>
                    </router-link>
                  </v-card>

                </div>
                <div class="col-sm-12 text-center" v-show="loadMoreText !== 0">
                  <v-btn color="primary" class="load-button" @click="loadMoreResults"> {{ $t('2023.load_more_results') }} </v-btn>
                </div>
              </div>
            </div>
            <div class="search-results-none" v-show="input === 1 && resultCompanies.length === 0 ">{{ $t('2023.no_results_found') }}</div>
            <div class="progress_class" v-show="isProgress">
              {{ $t('2023.loading_results') }}
            </div>
            <v-progress-linear color="primary" v-show="isProgress" class="progress_class" indeterminate :height="12"></v-progress-linear>
          </div>
      </div>
      <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
      ><v-icon>keyboard_arrow_up</v-icon></v-btn>
    </v-app>
</template>
<script>
import Axios from 'axios'
import { API } from '@/config/api.config'
import { Capacitor } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'DefaultPage',
  data: () => ({
    message: '',
    loading: false,
    items: [],
    closeOnContentClick: true,
    input: -1,
    theSearch: '',
    theSearchLocation: '',
    theSearchLocationResults: null,
    timer: null,
    resultCompanies: [],
    totalPages: 0,
    currentPage: 1,
    isProgress: null,
    selectedCategory: null,
    displayCategories: false,
    loadMoreButton: '',
    loadMoreText: '',
    fab: false,
    center: { lat: 45.508, lng: -73.587 }
  }),
  computed: {
    ...mapGetters({
      selectedLang: 'Setting/langState',
      langsOptions: 'Setting/langOptionState'
    })
  },
  methods: {
    ...mapActions({
      langChangeState: 'Setting/setLangAction'
    }),
    getTrans (key) {
      return this.$t(key)
    },
    langChange (lang) {
      this.langChangeState(lang)
      this.$i18n.locale = lang.value
      this.items = [
        { title: this.$t('2023.i_am_client'), to: 'customer-auth.login', icon: 'fas fa-solid fa-user' },
        { title: this.$t('2023.i_am_company'), to: 'auth1.login', icon: 'fas fa-solid fa-user-tie' }
      ]
    },
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 50
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    changeUrl () {
      const theQuery = {}
      let theResults = false
      theQuery.t = new Date().getTime()
      if (this.selectedCategory && this.selectedCategory.length > 0) {
        theQuery.category = this.selectedCategory
        theResults = true
      }
      if (this.theSearch && this.theSearch.length > 0) {
        theQuery.search = encodeURIComponent(this.theSearch)
        theResults = true
      }
      if (this.theSearchLocation && this.theSearchLocation.length > 0) {
        theQuery.location = encodeURIComponent(this.theSearchLocation)
        theResults = true
      }
      if (Object.keys(theQuery).length !== 0) {
        if (this.$router.query !== theQuery) {
          this.$router.push({ query: theQuery })
        }
      } else {
        this.$router.push({ query: theQuery })
      }
      if (theResults === true) {
        this.getResults()
      } else {
        this.resultCompanies = []
        this.input = -1
      }
    },
    setLocation (location) {
      this.theSearchLocationResults = null
      this.theSearchLocation = location
      this.displayCategories = false
      this.changeUrl()
    },
    deleteInput () {
      this.theSearch = ''
      this.changeUrl()
    },
    deleteLocation () {
      this.theSearchLocation = ''
      this.changeUrl()
    },
    removeSelectedCategory () {
      this.selectedCategory = null
      this.changeUrl()
    },
    showCategories () {
      this.$refs.theSearchBar.scrollIntoView({ behavior: 'smooth' })
      if (!this.selectedCategory && !this.theSearch && !this.theSearchLocation) {
        this.displayCategories = true
      } else {
        this.displayCategories = false
      }
      this.changeUrl()
    },
    hideCategories () {
      this.displayCategories = false
    },
    getAutocompleteLocation () {
      Axios.get(' https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json?apiKey=294btYrAjpqpwWBwcE1xaFfabhmrA9Vpt_kPFt0-kZw&resultType=city&query=' + this.theSearchLocation)
        .then((response) => {
          this.theSearchLocationResults = response.data.suggestions
        })
        .catch(() => {
        })
    },
    getCompaniesByCategory (type) {
      this.selectedCategory = type
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.hideCategories()
        this.changeUrl()
      }, 1000)
    },
    showSearch () {
      this.showCategories()

      if (!this.displayCategories) {
        this.input = 1
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.changeUrl()
        }, 800)
      } else {
        this.input = -1
      }
    },
    getResults (page = null) {
      const postData = {
        name: this.theSearch,
        category: this.selectedCategory,
        location: this.theSearchLocation,
        page: page
      }
      this.isProgress = true
      this.input = -1
      Axios.post(API.API_GET_COMPANIES_ANONYMOUS, postData)
        .then((response) => {
          if (response.data.status === 'success') {
            if (page) {
              this.resultCompanies = this.resultCompanies.concat(response.data.companies)
              console.log(this.resultCompanies)
            } else {
              this.resultCompanies = response.data.companies
            }
            this.isProgress = null
            this.input = 1
            if (response.data.nextPage !== '' || response.data.nextPage !== 0) {
              this.loadMore(response.data.nextPage)
            }
          }
        })
        .catch(() => {
          this.isProgress = null
          this.input = 1
        })
    },
    loadMore (page) {
      if (page !== 0) {
        this.loadMoreText = page
      } else {
        this.loadMoreText = 0
      }
      this.loadMoreButton = true
    },
    loadMoreResults () {
      this.getResults(this.loadMoreText)
    },
    locatorButtonPressed () {
      console.log(Capacitor.getPlatform())
      if (Capacitor.getPlatform() !== 'web') {
        Geolocation.requestPermissions()
        const printCurrentPosition = async () => {
          const coordinates = await Geolocation.getCurrentPosition()
          console.log('Current position:')
          console.log(coordinates)
          this.center.lat = coordinates.coords.latitude
          this.center.lng = coordinates.coords.longitude
        }
        console.log(printCurrentPosition())
      } else {
        navigator.geolocation.getCurrentPosition(
          position => {
            this.center.lat = position.coords.latitude
            this.center.lng = position.coords.longitude
            console.log(position)
          },
          error => {
            console.log(error.message)
          }
        )
      }
    }
  },
  mounted () {
    this.items = [
      { title: this.$t('2023.i_am_client'), to: 'customer-auth.login', icon: 'fas fa-solid fa-user' },
      { title: this.$t('2023.i_am_company'), to: 'auth1.login', icon: 'fas fa-solid fa-user-tie' }
    ]
    this.locatorButtonPressed()
    let doQueryAtBegining = false
    if (this.$route.query.category && this.$route.query.category !== '') {
      this.selectedCategory = this.$route.query.category
      doQueryAtBegining = true
    }
    if (this.$route.query.search && this.$route.query.search !== '') {
      this.theSearch = decodeURIComponent(this.$route.query.search)
      doQueryAtBegining = true
    }
    if (this.$route.query.location && this.$route.query.location !== '') {
      this.theSearchLocation = decodeURIComponent(this.$route.query.location)
      doQueryAtBegining = true
    }

    if (doQueryAtBegining) {
      this.getResults()
    }
  }
}
</script>
<style>
@import url("../../../assets/css/custom.css");
body {
  background-color: #f0f2f5 !important;
  font-family: Roboto, sans-serif;
}
.company_logo{
  object-fit: 100%;
  width: 70px;
  height: 70px;
  border-radius: 66px !important;
  border: 2px solid white;
}
.availability-chips{
  font-size:14px;
}
.search-result-companies{
  position: absolute;
  left: -40%;
  width: 180%;
}
.todays_avalability{
  font-size: 13px;
  color: black;
  margin-top: -30px;
}
.company_name{
  z-index: 999999;
  display: block;
  position: relative;
  margin-top: -70px;
}
.company_name img{
  z-index: 999999;
  border: 2px solid #fefefe !important;
}
.company_name div{
  margin-top: -44px;
  color: white;
  margin-left: 70px;
  line-height: 20px;
  font-size: 16px;
  font-weight: bold;
  padding: 4px;
  background: #0e0e0ea6;
}
.the-address{
  font-size: 11px !important;
  font-family: Arial !important;
  font-weight: bold;
  color: #000;
  margin-top: -10px !important;
}
.default-head {
  background-size: cover;
  background-position: center 0;
  min-height: 1500px;
}
.search-result-category{
  background: #ffffff47;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  padding:10px;
}
.search-head{
  background: #ffffffa8;
  border-radius: 5px;
  height: 36px;
  width: 50%;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;

  margin: auto;
}
#search, #search1{
  background: transparent !important;
  border: 0px !important;
  border-radius: 3px;
  height: 35px;
  line-height: 35px;
  color: #1976d2;
  font-weight: bold;
  font-family: 'Muli', "Times New Roman";
  display: inline;
  margin-right: 10px;
}
#search{
  width:50%;
}
#search1{
  width:30%;
}
.search-button{
  position:absolute;
  right: 0px;
  height: 35px;
}
.top-logo{
  text-align: center;
}
.top-logo img{
  height: 40px;
  margin-top:10px;
}
.top-menu{
  position: absolute;
  right: 10px;
  top:10px;
}
.the-iq-card{
  height: 100px;
  position: relative;
  color: #2197c0;
  text-align: center;
  cursor: pointer;
}
.the-iq-card:hover{
  background: #2197c0 !important;
  color: white;
}
.the-iq-card i{
  font-size: 40px;
  margin-top: 10px;
}
.the-iq-card div div{
  position: absolute;
  bottom: 5px;
  width: 100%;
  text-align: center;
  font-size: 20px;
}
.progress_class{
  text-align: center;
  color: #fff;
  text-shadow: 2px 2px 2px black;
  padding-top: 20px;
  font-size: 20px;
  margin-top: 20px;
}
.company_background .v-image__image{
  background-size: 100% !important;
}
.category_selected{
  position: absolute;
  background: #2197c0;
  color: white;
  padding: 5px;
  margin-top: 3px;
  margin-left: 3px;
  font-family: 'Muli';
  font-size: 12px;
  border-radius: 3px;
  width: 120px;
}
.category_selected i {
  float: right;
  margin-top: 3px;
}
.category_selected_text{
  padding-left: 125px !important;
}
.search-location{
  position: absolute;
  left: 55%;
  width: 35%;
  z-index: 9999999;
  background: white;
}
.search-location li {
  border-bottom: 1px solid grey;
}
.set-location:hover{
  cursor: pointer;
  background: #2197c0;
  color: white;
}
.search-results-none{
  text-align: center;
  margin-top: 50px;
  font-size: 30px;
  color: #fff;
  text-shadow: 1px 1px 1px #9e9e9e;
}
.remove_results {
  position: absolute;
  margin-left: -30px;
  margin-top: 10px;
  color: #2197c0;
}
@media only screen and (max-width: 1000px){
  .search-head{
    width: 99%;
    top: auto;
    margin-top: 10px;
  }
  .top-logo {
    text-align: left;
    padding-left: 10px;
  }
  .search-result-companies{
    left: 0;
    width: 100%;
  }
}
@media only screen and (max-width: 800px){
  .the-iq-card i {
    font-size: 30px;
  }
  .the-iq-card div div{
    font-size: 17px;
  }
}
@media only screen and (max-width: 600px){
  .search-button{
    display: none;
  }
  .the-iq-card div div{
    font-size: 14px;
  }
  .search-result-category .row .col-3{
    padding: 5px !important;
    margin: 0px !important;
  }
  .default-head{
    min-height: 800px;
  }
}
</style>
